import styled from "@emotion/styled";

export const mainPadding = "1rem";

export const MainContainer = styled.div`
  padding: 0 ${mainPadding} calc(${mainPadding} + env(safe-area-inset-bottom))
    ${mainPadding};
  max-width: 48rem;
  margin: 0 auto;
`;
