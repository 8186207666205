import { css } from "@emotion/react";
import styled from "@emotion/styled";
import CardActionArea, {
  CardActionAreaProps,
} from "@material-ui/core/CardActionArea";
import React from "react";
import { theme } from "../../libs/styled";
import { LinkProps } from "../../types/link-props";
import MuiLink from "../mui-link";
import { GameInfoSubheader, GameInfoTitle } from "../typography";

interface CardProps extends CardActionAreaProps, LinkProps {
  background: string;
  component?: any;
}

interface Props extends CardProps {
  cardTitle: string;
  cardSubheader: string;
  link?: LinkProps;
  topChildren?: React.ReactNode;
}

const CARD_PADDING = "1rem";

export const BaseCardStyles = css`
  width: 100%;
  border-radius: ${theme.borderRadius.card};
  box-shadow: ${theme.boxShadow.card};
  list-style: none;
  height: 8rem;
  margin-bottom: 1rem;
`;

const StyledCard = styled(CardActionArea)<CardProps>`
  ${BaseCardStyles}
  background: ${(props) => props.background};
  padding: ${CARD_PADDING};
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: inherit;
`;

const GameInfoContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: ${CARD_PADDING};
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 2rem);
`;

const StyledGameInfoSubheader = styled(GameInfoSubheader)`
  color: ${(props) => props.theme.color.white};
  margin: 0;
  max-width: min(calc(100vw - 4rem), 48rem);
`;

const StyledGameInfoTitle = styled(GameInfoTitle)`
  color: ${(props) => props.theme.color.white};
  text-transform: none;
  max-width: min(calc(100vw - 4rem), 48rem);
`;

const BaseCard: React.FC<Props> = React.memo(
  ({
    background,
    cardTitle,
    cardSubheader,
    children,
    topChildren,
    link,
    ...props
  }) => (
    // @ts-ignore
    <StyledCard
      background={background}
      {...(link ? { component: MuiLink, href: link.href, as: link.as } : {})}
      {...props}
    >
      {topChildren}
      <GameInfoContainer>
        {children}
        <StyledGameInfoSubheader>{cardSubheader}</StyledGameInfoSubheader>
        <StyledGameInfoTitle>{cardTitle}</StyledGameInfoTitle>
      </GameInfoContainer>
    </StyledCard>
  )
);

export default BaseCard;
